import { isBrowser } from "../../../utils";
import { AUTH_MODE, CURRENT_USER, OKTA_TOKEN, TOKEN } from "../constants";
import { clearTokenManager } from "./oktaAuth";

const resetAuth = () => {
  if (isBrowser()) {
    window.localStorage.removeItem(OKTA_TOKEN);
    window.localStorage.removeItem(AUTH_MODE);
    window.localStorage.removeItem(TOKEN);
    window.localStorage.removeItem(CURRENT_USER);
  }

  clearTokenManager();
};

export default resetAuth;